(function () {
  'use strict';
  // console.log('passe');
  // var isOldIE = (/(^| )oldie/).test(document.documentElement.getAttribute('class'));

  // if (isOldIE) { return; }

  var magentoWorkaround = function (player) {
    if (window.Prototype) {
      var intervalID = window.setInterval(function () {
        if (player.getPlayerState && player.getPlayerState() === 1) {
          var iframe = player.getIframe();
          var classStr = iframe.getAttribute('class');

          iframe.setAttribute('class', classStr + ' timelapse__video--running');
          window.clearInterval(intervalID);
        }
      }, 500);
    }
  };

  window.onYouTubeIframeAPIReady = function () {
    // window.alert('api ready');

    var id = 'timelapse-video';
    var element = document.getElementById(id);

    if (!element) { return; }

    var videoURL = element.getAttribute('data-video');
    // var strippedURL = videoURL.replace(/(.*\/)?(.*\?)?(.*)/, '$3');
    // var videoID = strippedURL.replace(/v=([^&]*)/, '$1');

    var youtubeParser = function (url) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      var match = url.match(regExp);

      return match && match[7].length === 11 ? match[7] : false;
    };

    var videoID = youtubeParser(videoURL);

    // console.log(videoID);

    var onPlayerStateChange = function (event) {
      // window.alert('state change : ' + event.data);
      var isPlaying = event.data === 1;
      var iframe = document.getElementById(id);
      var classStr = iframe.getAttribute('class');

      if (isPlaying) {
        iframe.setAttribute('class', classStr + ' timelapse__video--running');
      }
    };

    var onPlayerReady = function (event) {
      // window.alert('ready' + event.target.playVideo);
      var iframe = document.getElementById(id);

      var setIframeSize = function () {
        var ratio = 0.5625;
        var parentWidth = iframe.parentElement.offsetWidth;
        var parentHeight = iframe.parentElement.offsetHeight;
        var cropWidth = parentHeight > parentWidth * ratio;

        var iframeW = cropWidth ? parentHeight / ratio : parentWidth;
        var iframeH = cropWidth ? parentHeight : parentWidth * ratio;

        // console.log(cropWidth);

        iframe.setAttribute('width', Math.round(iframeW));
        iframe.setAttribute('height', Math.round(iframeH));
      };

      event.target.playVideo();
      event.target.setVolume(0);
      event.target.mute();
      // event.target.mute();
      // event.target.mute();
      // event.target.mute();

      setIframeSize();

      window.addEventListener('resize', setIframeSize);
    };

    var player = new window.YT.Player(id, {
      height: '360',
      width: '640',
      // wmode: 'transparent', // enable z-index on ifram with flash
      playerVars: {
        autohide: 1,
        autoplay: 1,
        wmode: 'transparent',
        playlist: videoID,
        controls: 0,
        fs: 0,
        modestBranding: 1,
        showinfo: 0,
        loop: 1
      },
      videoId: videoID,
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange
      }
    });

    magentoWorkaround(player);
  };
}());
